import React from "react";
import { FaFacebook, FaLinkedin, FaTwitter, FaLink } from "react-icons/fa";

const SocialShare = ({ postUrl, postTitle }) => {
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    postUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    postUrl
  )}&text=${encodeURIComponent(postTitle)}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    postUrl
  )}`;

  const onLinkButtonClick = () => {
    console.log(postUrl);
    document.addEventListener(
      "copy",
      function (e) {
        e.clipboardData.setData("text/plain", postUrl);
        e.preventDefault();
      },
      true
    );

    document.execCommand("copy");
  };

  return (
    <ul className="p-0 mb-0 mt-3 d-flex list-unstyled">
      <li className="me-2 p-2 bg-orange rounded-circle">
        <button
          className="social-link bg-transparent border-0 p-0 d-flex"
          onClick={onLinkButtonClick}
        >
          <FaLink style={socialIcons} />
        </button>
      </li>
      <li className="me-2 p-2 bg-orange rounded-circle">
        <a
          href={linkedInShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <FaLinkedin style={socialIcons} />
        </a>
      </li>
      <li className="me-2 p-2 bg-orange rounded-circle">
        <a
          href={twitterShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <FaTwitter style={socialIcons} />
        </a>
      </li>
      <li className="d-inline-block p-2 bg-orange rounded-circle">
        <a
          href={facebookShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <FaFacebook style={socialIcons} />
        </a>
      </li>
    </ul>
  );
};

const socialIcons = {
  color: "#fff",
  width: "16px",
  height: "16px",
  display: "flex",
  transition: "all 200ms cubic-bezier(0.2, 0, 0.38, 0.9)",
};

export default SocialShare;
