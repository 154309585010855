import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import HeroSection from "../components/hero-section";
import uuid4 from "uuid4";
import BlogCard from "../components/blogCard";
import Button from "react-bootstrap/Button";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { formattedDate } from "../utils/dateutils";
import SocialShare from "../components/social-share";
import SectionLayout6 from "../components/section-layout-6";
import { SafeHtmlParser } from "../components/safe-html-parser";

const BlogPostTemplate = ({ data: { post, site, allWpPost } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const postUrl = `${site.siteMetadata.siteUrl}/resources/${post.slug}`;

	const [relatedPostData, setRelatedPostData] = useState([]);
	const [numOfItemsToShow, setNumberOfItems] = useState(3);

	useEffect(() => {
		const postListData = [];
		const { terms } = post;
		if (terms.nodes?.length > 0) {
			let allCatList = [];
			terms.nodes.map((item) => {
				allCatList.push(item.slug);
			});

			allWpPost.nodes.filter((item) => {
				if (
					item.slug !== post.slug &&
					item.terms.nodes.some((e) => allCatList.includes(e.slug))
				) {
					postListData.push(item);
				}
			});
			setRelatedPostData(postListData);
		}
	}, []);

	const catName = (catList) => {
		let outStr = "";
		if (catList.length > 0) {
			catList.forEach(
				(ele, idx) =>
					(outStr += `${ele.name} ${idx < catList.length ? "" : ", "}`)
			);
		}

		return outStr;
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/resources/${post.slug}`,
					id: `${siteUrl}/resources/${post.slug}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={post.seoFieldGroups?.metaTitle}
				description={post.seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${post.seoFieldGroups?.openGraphTitle}`,
					description: `${post.seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${post.seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${post.seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${post.seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${post.seoFieldGroups?.image?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<HeroSection
					title={post.title}
					backgroundImage={
						post.postFields?.featureImage.localFile.childImageSharp
							.gatsbyImageData
					}
				/>
				<section className="pt-8">
					<div className="blog-bottom-content">
						<div className="d-flex justify-content-end align-items-center">
							{post.postFields.authorImage?.localFile.childImageSharp
								.gatsbyImageData ? (
								<GatsbyImage
									image={
										post.postFields.authorImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={post.postFields.authorImage?.altText}
									className="rounded-circle"
									style={{ width: "56px" }}
								/>
							) : (
								<StaticImage
									src="../images/avatar.svg"
									alt="avatar"
									style={{ width: "56px" }}
									className="bg-white rounded-circle"
								/>
							)}
							<div className="ps-2 ps-md-3">
								<span className="small-text roboto-bold">
									{post.postFields.authorName}
								</span>
								<div className="d-flex roboto-regular align-items-center small-text">
									{formattedDate(post.postFields.date)}
									{post.postFields.duration && (
										<span className="seprator">{post.postFields.duration}</span>
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						className="bg-white blog-default-content"
						dangerouslySetInnerHTML={{
							__html: post.postFields.body,
						}}
					></div>
				</section>
				<div className="blog-bottom-content">
					<Row className="justify-content-between align-items-end pb-5 border-bottom border-secondary">
						<Col md={6}>
							<p className="roboto-bold mb-0">Share this post</p>
							<SocialShare postTitle={post.title} postUrl={postUrl} />
						</Col>
						{post.tags && post.tags.nodes.length > 0 && (
							<Col md={6} className="text-md-end">
								<ul className="p-0 mb-0 mt-4 small-text">
									{post.tags.nodes.map((item) => (
										<li
											key={uuid4()}
											className="d-inline-block me-3 roboto-bold text-uppercase"
										>
											{item.name}
										</li>
									))}
								</ul>
							</Col>
						)}
					</Row>
					<div className="d-flex py-5 align-items-center">
						{post.postFields.authorImage?.localFile.childImageSharp
							.gatsbyImageData ? (
							<GatsbyImage
								image={
									post.postFields.authorImage?.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={post.postFields.authorImage?.altText}
								className="rounded-circle"
								style={{ width: "56px" }}
							/>
						) : (
							<StaticImage
								src="../images/avatar.svg"
								formats={["auto", "webp"]}
								alt="avatar"
								style={{ width: "56px" }}
								className="bg-white rounded-circle"
							/>
						)}
						<div className="ps-2 ps-md-3">
							<span className="fs-6 roboto-bold">
								{post.postFields.authorName}
							</span>
							<div
								className="d-flex roboto-regular"
								style={{ fontSize: "14px" }}
							>
								{post.postFields.authorJobTitle}
								{"  "}
								{post.postFields.authorCompanyName && (
									<>, {post.postFields.authorCompanyName}</>
								)}
							</div>
						</div>
					</div>
				</div>

				{relatedPostData.length > 0 && (
					<section className="related-post my-7">
						<Container>
							<Row className="justify-content-center">
								<Col lg={12} className="text-center">
									<h2>Related Posts</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
									</p>
								</Col>
							</Row>

							<Row className="my-7">
								{relatedPostData.slice(0, numOfItemsToShow).map((postItem) => (
									<Col lg={4} key={uuid4()}>
										<BlogCard
											title={postItem?.title}
											image={
												postItem.postFields.featureImage?.localFile
													.childImageSharp.gatsbyImageData
											}
											uri={`/resources/${postItem.slug}`}
											category={catName(postItem.terms?.nodes)}
											imageAlt={"blog"}
										/>
									</Col>
								))}
							</Row>
							{relatedPostData && numOfItemsToShow < relatedPostData.length && (
								<Button
									size="lg"
									className="px-5 py-3 robotosarif-medium fs-6 bg-transparent border-secondary my-7 d-flex align-items-center mx-auto text-secondary text-uppercase "
									onClick={() => setNumberOfItems(relatedPostData.length)}
									variant="secondary"
								>
									View All
									<StaticImage
										className="ms-3"
										src="../images/arrow-yellow.svg"
										formats={["auto", "webp"]}
										alt="arrow"
										style={{ width: "34px" }}
									/>
								</Button>
							)}
						</Container>
					</section>
				)}
				{post.postFields.ctaSection && post.postFields.ctaSection.heading && (
					<SectionLayout6
						heading={
							<SafeHtmlParser
								htmlContent={post.postFields.ctaSection?.heading}
							/>
						}
						ctaButton={post.postFields.ctaSection?.link}
					/>
				)}
			</Layout>
		</>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			slug
			title
			date
			tags {
				nodes {
					slug
					name
				}
			}
			postFields {
				fieldGroupName
				duration
				date
				body
				authorName
				authorCompanyName
				authorJobTitle
				ctaSection {
					fieldGroupName
					heading
					link {
						target
						title
						url
					}
				}
				authorImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
								width: 140
							)
						}
					}
				}
				featureImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
								width: 140
							)
						}
					}
				}
			}
			terms {
				nodes {
					slug
					name
				}
			}
			seoFieldGroups {
				metaTitle
				metaDescription
				# localBusinessSchema
				openGraphDescription
				openGraphTitle
				# productSchema
				image {
					sourceUrl
					altText
					publicUrl
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
		}
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				title
				slug
				excerpt
				date
				tags {
					nodes {
						slug
						name
					}
				}
				postFields {
					fieldGroupName
					duration
					date
					body
					authorName
					authorImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
									width: 140
								)
							}
						}
					}
					featureImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
									width: 140
								)
							}
						}
					}
				}
				terms {
					nodes {
						slug
						name
						... on WpCategory {
							name
							parentId
							slug
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
