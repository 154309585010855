import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogCard = ({ title, image, uri, category, imageAlt }) => {
  return (
    <Card className="w-100 mb-4 border-0">
      <Link to={uri}>
        <Card.Img
          as={GatsbyImage}
          variant="top"
          image={image}
          alt={imageAlt}
          style={{ height: "360px" }}
        />
      </Link>
      <Card.Body className="bg-white ">
        <Card.Text className="mt-3">
          {category && (
            <span
              className="py-2 px-3 text-blue-bg roboto-medium fs-6"
              style={{
                borderRadius: "3px",
                background: "rgba(255, 176, 57, 0.50)",
              }}
            >
              {category}
            </span>
          )}
        </Card.Text>

        <Card.Title className="d-flex fs-4 lh-base mt-4 mb-0 robotosarif-medium">
          {title}
        </Card.Title>

        {/* <Col xs={12} className="d-flex justify-content-center">
          <Button
            variant="primary"
            size="vSmall"
            className="text-sm"
            as={Link}
            to={`/posts${uri}`}
          >
            Read More
          </Button>
        </Col> */}
      </Card.Body>
    </Card>
  );
};

export default BlogCard;
